console.log("HI WORLD");
import gsap from "gsap";
import barba from "@barba/core";

export const pageTransition = () => {
  const transitionOut = () =>
    gsap.to("main", {
      opacity: 0,
      y: -50,
      duration: 1.2,
      ease: "power2.inOut",
    });

  const transitionIn = () =>
    gsap.fromTo(
      "main",
      {
        opacity: 0,
        y: -50,
      },
      {
        opacity: 1,
        y: 0,
        duration: 1.2,
        ease: "power2.inOut",
      },
    );

  barba.init({
    sync: true,
    transitions: [
      {
        async leave(data) {
          await transitionOut();
          data.current.container.remove();
        },
        async enter() {
          await transitionIn();
        },
      },
    ],
  });
};
