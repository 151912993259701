//import '/assets/css/main.scss'
// Focus Visible Polyfill
import { inject } from "@vercel/analytics";
import "focus-visible";

// External Modules
import barba from "@barba/core";
import Lenis from "lenis";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import Splitting from "splitting";
import ScrollOut from "scroll-out";

import { pageTransition } from "./modules/transitions";

// Function to initialize page-specific scripts
function initPageScripts() {
  console.log("Initializing page-specific scripts");

  Splitting();

  ScrollOut({
    threshold: 0.5,
    once: false,
  });

  // Add any other page-specific initializations here
}

// Initial page load
pageTransition();
initPageScripts();

// Barba.js hooks
barba.init({
  transitions: [
    {
      name: "opacity-transition",
      leave(data) {
        return gsap.to(data.current.container, {
          opacity: 0,
        });
      },
      enter(data) {
        return gsap.from(data.next.container, {
          opacity: 0,
        });
      },
    },
  ],
  views: [
    {
      namespace: "all",
      afterEnter() {
        initPageScripts();
      },
    },
  ],
});

barba.hooks.after(() => {
  console.log(transitions);
  console.log("Transition complete, page loaded");
});

const lenis = new Lenis({
  duration: 1.98,
  easing: (t) => (t === 1 ? 1 : 1 - Math.pow(2, -10 * t)),
  direction: "vertical",
  gestureDirection: "vertical",
  smoothWheel: true,
  smoothTouch: false,
  wheelMultiplier: 1.98,
});

ScrollTrigger.addEventListener("refresh", () => lenis.resize());

lenis.on("scroll", ScrollTrigger.update);

gsap.ticker.add((time) => {
  lenis.raf(time * 1000);
});

gsap.ticker.lagSmoothing(0);

document.getElementById("bentoMenu").addEventListener("click", function () {
  const menu = this;

  // Проверяем, активен ли элемент
  if (menu.classList.contains("active")) {
    // Анимация закрытия и возврата к исходным позициям
    gsap.to(menu, { rotation: 0, duration: 0.5 });

    gsap.to(menu.querySelector("span:nth-child(1)"), {
      top: "0",
      left: "0",
      transform: "translate(0, 0)",
      duration: 0.5,
      onComplete: function () {
        menu.querySelector("span:nth-child(1)").style.transform = "";
      },
    });

    gsap.to(menu.querySelector("span:nth-child(3)"), {
      top: "0",
      left: "100%",
      transform: "translate(-100%, 0)",
      duration: 0.5,
      onComplete: function () {
        menu.querySelector("span:nth-child(3)").style.transform = "";
      },
    });

    gsap.to(menu.querySelector("span:nth-child(7)"), {
      top: "100%",
      left: "0",
      transform: "translate(0, -100%)",
      duration: 0.5,
      onComplete: function () {
        menu.querySelector("span:nth-child(7)").style.transform = "";
      },
    });

    gsap.to(menu.querySelector("span:nth-child(9)"), {
      top: "100%",
      left: "100%",
      transform: "translate(-100%, -100%)",
      duration: 0.5,
      onComplete: function () {
        menu.querySelector("span:nth-child(9)").style.transform = "";
      },
    });
  } else {
    // Анимация открытия и перемещение в центр
    gsap.to(menu, { rotation: 45, duration: 0.5 });

    gsap.to(menu.querySelector("span:nth-child(1)"), {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      duration: 0.5,
    });

    gsap.to(menu.querySelector("span:nth-child(3)"), {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      duration: 0.5,
    });

    gsap.to(menu.querySelector("span:nth-child(7)"), {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      duration: 0.5,
    });

    gsap.to(menu.querySelector("span:nth-child(9)"), {
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      duration: 0.5,
    });
  }

  // Переключаем класс
  menu.classList.toggle("active");
});

function formatDate() {
  const date = new Date();
  const days = ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"];
  const dayName = days[date.getDay()];
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Месяцы начинаются с 0, поэтому добавляем 1

  return `${dayName} ${day}.${month}`;
}

// Вывод даты в HTML
document.getElementById("date-display").textContent = formatDate();

// Функция для генерации случайных координат
function getRandomCoordinates() {
  // Случайная широта от -90 до 90
  const latitude = (Math.random() * 180 - 90).toFixed(6);
  // Случайная долгота от -180 до 180
  const longitude = (Math.random() * 360 - 180).toFixed(6);
  return { latitude, longitude };
}

// Функция для форматирования координат
function formatCoordinates(latitude, longitude) {
  // Функция для перевода десятичных градусов в градусы и минуты
  function toDegreesMinutes(coord) {
    const degrees = Math.floor(Math.abs(coord));
    const minutes = Math.floor((Math.abs(coord) - degrees) * 60);
    return `${degrees}°${minutes}'`;
  }

  // Определяем северное/южное и восточное/западное направление
  const latDirection = latitude >= 0 ? "N" : "S";
  const longDirection = longitude >= 0 ? "E" : "W";

  // Форматируем широту и долготу
  const formattedLat = `${toDegreesMinutes(latitude)}${latDirection}`;
  const formattedLong = `${toDegreesMinutes(longitude)}${longDirection}`;

  // Возвращаем широту и долготу с переносом строки
  return `${formattedLat}<br>${formattedLong}`;
}

// Получаем случайные координаты
const randomCoords = getRandomCoordinates();

// Выводим случайные координаты в HTML
document.getElementById("coordinates-display").innerHTML = formatCoordinates(
  randomCoords.latitude,
  randomCoords.longitude,
);

//HEADER ANIMATION
const menu = document.querySelector(".menu");
const overlayBg = document.querySelector(".overlay-menu");
const header = document.querySelector(".header");

function openMenu() {
  if (!header.classList.contains("active-menu")) {
    header.classList.add("active-menu");
    let tl = gsap.timeline();
    gsap.set(".main-menu--items li", { x: -167 });
    gsap.set(".main-social--items li", { x: -167 });
    tl.to(".overlay-menu", {
      duration: 0.3,
      opacity: 1,
      visibility: "visible",
      zIndex: 15,
    })
      .to(
        ".main-menu",
        {
          duration: 0.7,
          y: 0,
          ease: "expo.inOut",
        },
        0,
      )
      .to(
        ".main-menu--items li",
        {
          x: 0,
          stagger: 0.1,
          ease: "power3.inOut",
        },
        0.5,
      )
      .to(
        ".main-social--items li",
        {
          x: 0,
          stagger: 0.1,
          ease: "power3.inOut",
        },
        0.5,
      )
      .to(".main-menu--socials div", {
        opacity: 1,
        stagger: 0.1,
        ease: "power3.inOut",
      });
  }
}

function closeMenu() {
  if (header.classList.contains("active-menu")) {
    header.classList.remove("active-menu");
    let tl = gsap.timeline();
    tl.to(".main-menu", {
      duration: 0.7,
      y: -482,
      ease: "expo.inOut",
    }).to(
      ".overlay-menu",
      {
        autoAlpha: 0,
        zIndex: -10,
      },
      0.4,
    );
  }
}

menu.addEventListener("click", function () {
  if (header.classList.contains("active-menu")) {
    closeMenu();
  } else {
    openMenu();
  }
});

overlayBg.addEventListener("click", function (e) {
  if (e.target.classList.contains("overlay-menu")) {
    closeMenu();
  }
});

document.getElementById("currentYear").textContent = new Date().getFullYear();
